import { combineReducers } from "@reduxjs/toolkit";
import adminReducer from './adminReducers'
import locationReducer from "./locationReducers";
const rootReducer = combineReducers({
    admin: adminReducer,
    location: locationReducer

    
})

export default rootReducer