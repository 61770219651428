import { AppBar, Box, Toolbar,Typography} from '@mui/material'
import React from 'react'
import NavBarLinks from '../../layouts/Landing/NavBarLinks'
import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme)=>({
  typo: {
    fontWeight: 'bold',
    color: '#fff',
    [theme.breakpoints.down('lg')]: {
      fontSize:'20px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize:'15px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize:'9px'
    },
  }
}))
const Header = () => {
  const classes = useStyles()
  return (
    <div>
      {/* <AppBar position='static'
      sx={{height:'80px'}}
      >
        <Toolbar>
          <Box sx={{display:'flex'}}>

            <img  src="/assets/images/TreeAlone-1.png"
            width="80px"
            />
            <Box sx={{display:'flex', alignItems:'center'}}>

              <Typography
      variant='h5'
      sx={{
        color:'#fff',
        ml:2
      }}
      
      >
        Apply in under a minute and hear from us within one business day!
      </Typography>
        </Box>
        </Box>
        </Toolbar>
        </AppBar>  */}
        <AppBar position='static'
        sx={{height:'80px'}}
        >
  <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src="/assets/images/TreeAlone-1.png" width="80px" alt="Logo" />
    </div>
    <Typography
    className={classes.typo}
      variant='h5'
      sx={{
        
      }}
    >
      Apply in under a minute and hear from us within one business day!
    </Typography>
      <div></div>
      </Toolbar>
        </AppBar>
    </div>
  )
}

export default Header
