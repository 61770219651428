import { AppBar, Box, Button, Card, Divider, 
  Grid, Toolbar, Typography, styled } from '@mui/material'
import React from 'react'
import DialogComp from './DialogComp';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
 typo : {
  mb:3, textAlign:'center', color:'#fff',
  [theme.breakpoints.down('md')]: {
    fontSize:'1.5rem'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize:'1.25rem'
  }

}
}))

const CenteredBox = styled(Box)(({theme})=> ({
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    // marginTop:theme.spacing(10),
  }))
 
  const StyledRoot = styled(Box)(({theme})=> ({
    background:theme.palette.secondary.main,
    paddingTop: theme.spacing(5),
    minHeight:'100vh'
  }))

  const StyledDepBox=styled(Box)(({theme})=> ({
    borderRadius:'10px',
    // background:theme.palette.secondary.main,
    background:'#e2e2e2',
    boxShadow: '0 4px 8px #822824',
    marginTop: theme.spacing(3),
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    marginTop: theme.spacing(5)
  }))
  const StyledChildBox=styled(Box)(({theme})=> ({
    borderTopLeftRadius:'5px',
    borderBottomLeftRadius:'5px',
    background:theme.palette.primary.main,
    width:'40%',
    padding: theme.spacing(2)
  }))
  const StyledChildBox2=styled(Box)(({theme})=> ({
    borderTopRighRadius:'5px',
    borderBottomRightRadius:'5px',
    background:'#d3d3d3',
    width:'60%',
    padding: theme.spacing(2)
  }))
  const StyledButton=styled(Button)(({theme})=> ({
    height:'50px'
  }))
  const OpenedPos = () => {
    const {id} = useParams()
    // console.log(id)

    const getDepartments = async () => {
      const res = await axios.post(` ${process.env.REACT_APP_URL}specific-department`, {facility_id:id})
    // console.log(res.data.position)
    setDeptData(res.data.position)  
    }
    const [deptData, setDeptData] = React.useState([])

    React.useEffect(()=> {
      getDepartments()
    
    }, [])
    const classes = useStyles()
  return (
    <div>
      <AppBar position='static'>
        <Toolbar>
        <img  src="/assets/images/TreeAlone-1.png"
            width="80px"
            />
            <Typography>
              Current Openings
            </Typography>
        </Toolbar>
        </AppBar> 
        <StyledRoot>

        <Typography variant='h4' fontWeight="bold"
        className={classes.typo}
        >
               Select the department applicable to you!
            </Typography>
            <Divider />
            <Box
            sx={{p:3}}
            >
            {/* <Typography
            variant='h5'
            sx={{color:'#fff', fontWeight:'bold'}}
            >
              Departments
            </Typography> */}
<Grid container
spacing={3}
>
  {deptData.map((val, ind)=> {

    return(
      <Grid item
      lg={6}
      md={6}
      sm={12}
      xs={12}
      >
          <StyledDepBox>
                      {/* <Typography sx={{fontWeight:'bold '}}>
                        {val.title}
                        </Typography> 
                        <Divider />
                        <Typography sx={{mt:2}}>
                          Positions Opened: {val.pos}
                        </Typography>
                        <Box
                        sx={{display:'flex', justifyContent:'center', mt:3}}
                        >
                        <Button variant='contained'
                        component={Link}
                        to='/current-openings'
                        
                        >
                          Apply Now 
                        </Button>
                        </Box> */}
                        <StyledChildBox>
                            <Typography sx={{color:'#fff',
                          textAlign:'center'
                          }}>
                              Department Name:
                            </Typography>
                            <Typography
                            variant='h5'
                            fontWeight="bold"
                            textAlign="center"
                            sx={{color:'#fff', mt:2}}
                            >
                              {val.department}
                            </Typography>
                        </StyledChildBox>
                        <StyledChildBox2>
                          {/* <Typography
                          sx={{textAlign:'center', mb:3}}
                          >
                            Positons opened: 12
                          </Typography> */}
                          <Box sx={{
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center'
                          }}>
                          <StyledButton
                          variant='contained'
                          sx={{mt:2}}
                          component={Link}
                          to={`/current-openings/${val.id}`}
                          >
                            Apply Now
                          </StyledButton>
                          </Box>
                        </StyledChildBox2>
                </StyledDepBox>
      </Grid>
    )
  })}
   
</Grid>
              
              </Box>
       
    

            </StyledRoot>
        
    </div>
  )
}

export default OpenedPos
