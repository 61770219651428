import { createTheme } from "@mui/material";

export const theme = createTheme({

    palette: {
        primary: {
          main: '#822824',
        },
        secondary: {
          main: '#BB4A22',
        },
      },
      components: {
        MuiCheckbox: {
          styleOverrides: {
            root: {
              borderColor: '#e2e2e2', // Replace with your desired border color
              '&.Mui-checked': {
                // Custom styles for the checked state if needed
                // For example, if you want a different border color when checked:
                borderColor: '#fff',
              },
            },
          },
        },
      },

});