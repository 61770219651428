import { Dialog, DialogContent, DialogTitle, 
  Divider, TextField, Typography, styled, Button, 
  DialogActions, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles'
import axios from 'axios'
import { useNavigate } from 'react-router'
import { RotatingLines } from 'react-loader-spinner'


const StyledDialog=styled(Dialog)(({theme})=> ({
  // background: theme.palette.secondary.main
}))
const StyledTitle=styled(DialogTitle)(({theme})=> ({
  background: theme.palette.primary.main
}))
const StyledContent = styled(DialogContent)(({theme})=> ({
  background: theme.palette.secondary.main
}))
const StyledAction = styled(DialogActions)(({theme})=> ({
  background: theme.palette.secondary.main
}))
const StyledButton = styled(Button)(({theme})=> ({
  marginTop: theme.spacing(0.5),
  background:'#eae552',
  width:'150px',
  height:'50px',
  color:'#000',
  marginRight:theme.spacing(4),
  '&:hover': {
    color:'#fff',
    background:'#822824'
  }
}))

const useStyles = makeStyles((theme)=> ({
  field: {
    marginBottom: theme.spacing(3)
  }
}))
const initialValues ={
  firstname:'',
  lastname:'',
  phone:'',
  email:'',
  address:'',
  experiece:'',
  messageupdate:'',
  position_id:2
}

const DialogComp = (props) => {
  const classes = useStyles()
  const [loading , setLoading] = React.useState(false)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const navigate = useNavigate()
  const [resumeFile, setResumeFile] = React.useState(null);
  const [resumeFileName, setResumeFileName] = React.useState('');
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setResumeFile(file);
    setResumeFileName(file ? file.name : '');
  };
  const [formValues, setFormValues] = React.useState(initialValues)
  const handleChange = (e) => {
    const {name, value} = e.target
    setFormValues({...formValues, [name]:value})
  }

  const handleChangeExp = (e) => {
    setFormValues({ ...formValues, experiece: e.target.value });
    };
    const handleChangeUp = (e) => {
      setFormValues({ ...formValues, messageupdate: e.target.value });
      };

      const handleSubmit = async  (e) => {
        setLoading(true)
          e.preventDefault()
          const updatedFormValues = {
            ...formValues,
            file: resumeFile,
            position_id: props.id
          }
          const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
          console.log(updatedFormValues)
          
       try{
        const res = await axios.post(`${process.env.REACT_APP_URL}application/add`, updatedFormValues, config)
        // alert(res.data.message)
        setLoading(false)
        setDialogOpen(true)
        // alert('Thank you so much for taking the time to apply, you will hear from us soon!');
        setFormValues(initialValues)
        // props.close()
      }
      catch(err){
        console.log(err)
      }
    }
    const handleDialogClose = () => {
      setDialogOpen(false)
      navigate('/')

      }
      // const handleSubmit = async (e) => {
      //   e.preventDefault();
      
      //   try {
      //     const formData = new FormData();
      //     formData.append('resume', resumeFile); // Append the resume file to the form data
      //     Object.keys(formValues).forEach((key) => {
      //       formData.append(key, formValues[key]);
      //     });
      //     const res = await axios.post(`${process.env.REACT_APP_URL}application/add`, formData);
      //     alert(res.data.message);
      //     setFormValues(initialValues);
      //     setResumeFile(null);
      //     setResumeFileName('');
      //     props.close();
      //   } catch (err) {
      //     console.log(err);
      //   }
      // };
  
  return (
    <div>
      
      <StyledDialog open={props.open} 
      onClose={props.close} fullWidth>
        <form
      onSubmit={handleSubmit}
      >
      <StyledTitle>
        <Typography
        variant='h5'
        sx={{fontWeight:'bold', color:'#d3d3d3'}}
        >
          Give us a way to reach you!
        </Typography>
        </StyledTitle>
        <Divider sx={{background:'#822824'}}/>
        <StyledContent>

           <TextField label="First Name" fullWidth  
           name='firstname' value={formValues.firstname}
           required
           onChange={handleChange}
           className={classes.field}
           />
           <TextField label="Last Name" fullWidth 
           name='lastname' value={formValues.lastname}
           required
           onChange={handleChange}
           className={classes.field}
           />
            <TextField label="Cell Number" fullWidth  
            name='phone' value={formValues.phone}
            required
            onChange={handleChange}
            className={classes.field}
            />
            <TextField label="Email" fullWidth
            name='email' value={formValues.email}
            type='email'
            required
            onChange={handleChange}
            className={classes.field}
            />
            <TextField label="Home Address" fullWidth
            name='address' value={formValues.address}
            required
            onChange={handleChange}
            className={classes.field}
            />
           <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Do you have expeirence working in a Long Term Care Facility?</InputLabel>
            <Select
            className={classes.field}
            labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                value={formValues.experiece}
                label="Do you have expeirence working in a Long Term Care Facility?"
                onChange={handleChangeExp}
            >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
            </Select>
            </FormControl>
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Do you consent to receive text message updates?</InputLabel>
            <Select
            className={classes.field}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
                required
                value={formValues.messageupdate}
                label="Do you consent to receive text message updates?"
                onChange={handleChangeUp}
                >
                <MenuItem value="1">Yes</MenuItem>
                <MenuItem value="0">No</MenuItem>
            </Select>
            </FormControl>
            <input
  type="file"
  accept=".pdf,.doc,.docx"
  onChange={handleFileUpload}
  style={{ display: 'none' }}
  id="resume-upload-input"
/>
<label htmlFor="resume-upload-input">
  <Button variant="outlined" component="span" sx={{color:'#fff'}}>
  {resumeFileName ? resumeFileName : 'Upload Resume'}
  </Button>
</label>
          </StyledContent> 
          <StyledAction>
          {
       loading ? <Button variant='disabled'>    <RotatingLines
       strokeColor="#eae552"
       strokeWidth="5"
       animationDuration="0.75"
       width="30"
       visible={loading}/> </Button> :
       <StyledButton
            type='submit'
            >
              Apply Now 
            </StyledButton>
     }
           
            {/* <Button variant='contained'
            onClick={handleSubmit}
            >
                Submit Application
            </Button> */}
            </StyledAction>      
  </form>
      </StyledDialog>
      <Dialog open={dialogOpen} fullWidth>
        <DialogTitle>
          Note 
        <Divider />
        </DialogTitle>
              <DialogContent>
                <Typography>
                Thank you for submitting. Your information will automatically be forwarded to 
                the facility you requested. Submission online does not constitute an application 
                or acceptance of an offer.  If accepted for a job, you will be 
                required to submit a full application in accordance with facility policies.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button variant='contained'
                onClick={handleDialogClose}
                >

                  Ok
                </Button>
              </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogComp
