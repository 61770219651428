import { Box, IconButton, Stack, Tooltip, Typography, styled } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter'; 
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
const StyledRoot = styled(Box)(({theme})=> ({
    minHeight:'30vh',
    background: theme.palette.primary.main,
    padding: theme.spacing(3),
    
}))
const Footer = () => {
  return (
    <StyledRoot>
      <Box sx={{
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
      }}>
        <Box>

        <Stack direction="row">
          <Tooltip
          title="Visit our Facebook page">
          <IconButton
          href='https://www.facebook.com/autumnlakehealthcare/'
          target='_blank'
          
          > <FacebookIcon sx={{color:'#fff'}}/> </IconButton>
          </Tooltip>
          <Tooltip
          title="Twitter"
          >
          <IconButton
          href='https://twitter.com/autumnlakehc?t=NyRxU9ph673SinpslKKeOg&s=08'
          target='_blank'
          > <TwitterIcon sx={{color:'#fff'}}/> </IconButton>
          </Tooltip>
          <Tooltip
          title="LinkedIn">
          <IconButton
          href='https://www.linkedin.com/company/autumn-lake-healthcare/'
          target='_blank'
          > <LinkedInIcon sx={{color:'#fff'}}/> </IconButton>
          </Tooltip>
          <Tooltip
          title="Instagram"
          >
          <IconButton
          href='https://www.instagram.com/autumnlakehc/'
          target='_blank'
          > <InstagramIcon sx={{color:'#fff'}}/> </IconButton>
          </Tooltip>
          <Tooltip
          title="TikTok"
          >

          <IconButton
          href='https://www.tiktok.com/@autumnlakehealthcare'
          target='_blank'
          > <AudiotrackIcon sx={{color:'#fff'}}/> </IconButton>
          </Tooltip>

          </Stack> 
          </Box>
        
      </Box>
          <Typography sx={{color:'#fff', mt:3, mb:3}}>
          Healthcare related services are provided exclusively by independently owned and operated facilities. Each healthcare facility is responsible for all employment matters in their facility, including but not limited to the terms and conditions of employment, hiring, discipline, supervision, staffing and scheduling.  If you are hired for a position at a facility, Autumn Lake Healthcare will not be your employer.  Each healthcare facility is an equal opportunity employer and is committed to diversity and inclusion in the workplace. Each healthcare facility prohibits discrimination and harassment of any kind based on race, color, sex, religion, sexual orientation, national origin, disability, genetic information, pregnancy, or any other protected characteristic as outlined by federal, state, or local laws.
          </Typography>
      <Typography
      component={Link}
      sx={{
        // textDecoration:'none',
        color:'white',
        fontWeight:'bold',
        float:'right'
        
      }}
      to="/admin-login"
      >
        Admin 
      </Typography>
    </StyledRoot>
  )
}

export default Footer
