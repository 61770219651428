import React from 'react'
import { TextField, Dialog, DialogActions, Button,
MenuItem, InputLabel, FormControl, DialogTitle, Divider,
Select, DialogContent
} from '@mui/material'
import { RotatingLines } from 'react-loader-spinner'
import { makeStyles } from '@mui/styles'
import axios from 'axios'
import { RestartAlt } from '@mui/icons-material'
const useStyles=makeStyles((theme)=> ({
    input: {
        marginBottom: theme.spacing(3)
    }
}))
const initialValues = {
    position:'',
    department_id:'',
    status:''
}
const AddPosition = (props) => {
  const [formValues, setFormValues] = React.useState(initialValues)
  const api = axios.create({
    baseURL: process.env.REACT_APP_URL,
  });
  const getToken = () => {
    return localStorage.getItem('token');
  };

  api.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const [loading, setLoading] = React.useState(false)
  const handleChange = (e) => {
      const {name, value} = e.target
      setFormValues({...formValues, [name]: value})
}
const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    try {
        const res = await api.post('positions', formValues)
        alert(res.data.message)
        setLoading(false)
        setFormValues(initialValues)
        props.close()
        if (props.posCreated) {
            props.posCreated()
        }
    }
    catch(err) {
        console.log(err)
    }

}
const handleChangeStatus = (e) => {
    setFormValues({ ...formValues, status: e.target.value });
  };
  const [facilitiesData, setFacilitiesData] = React.useState([])
  const getAllFacilities = async () => {
    try {
      const res = await api.get(`facilities`);
      console.log(res.data.data[0], 'The response')
      setFacilitiesData(res.data.data[0]);
      // console.log(res.data.data[0].current_page, '++++++')
      // setPaginationData({
      //   current_page: res.data.data[0].current_page,
      //   last_page: res.data.data[0].last_page,
      //   total: res.data.data[0].total,
      //   per_page: res.data.data[0].per_page,
      // }); 
    } catch (err) {
      console.log(err);
    }
  };
  // console.log(facilitiesData)
  React.useEffect(()=> {
    getAllFacilities()
  }, [])
  const [facility, setFacility] = React.useState("")
  const handleChangeFacility = (e) => {
    const selectedFacilityId = e.target.value;
    setFacility(selectedFacilityId);

    // Find the selected facility object from the facilitiesData array
    const selectedFacility = facilitiesData.find((facility) => facility.id === selectedFacilityId);

    if (selectedFacility) {
        setSelectedFacilityDepartments(selectedFacility.departments);
        setFormValues({ ...formValues, department_id: '' }); // Reset department selection
    }
};
  const handleChangeStatus2 = (e) => {
    setFormValues({ ...formValues, department_id: e.target.value });
  };
  const [selectedFacilityDepartments, setSelectedFacilityDepartments] = React.useState([]);

const classes = useStyles()
    return (
    <div>
    <Dialog fullWidth open={props.open} onClose={props.close}>
     <DialogTitle>
         Add Position
     </DialogTitle>
     <Divider />
         <form onSubmit={handleSubmit}>
     <DialogContent>

         <TextField fullWidth name='position' value={formValues.position}
         required className={classes.input}
         onChange={handleChange}
         label="Position"
         autoComplete='off'
         /> 
          <FormControl fullWidth
          className={classes.input}
          >
            <InputLabel id="demo-simple-select-label">Select Facility</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                value={facility}
                label="Select Facility"
                onChange={handleChangeFacility}
            >
              {
                facilitiesData.map((val, ind)=> {
                  return(
                    <MenuItem value={val.id}>{val.name}</MenuItem>
                  )
                })
              }

            </Select>
            </FormControl>
         {/* <TextField fullWidth name='department_id' value={formValues.department_id}
         required className={classes.input}
         onChange={handleChange}
         label="Department Id"
         autoComplete='off'
         />  */}
         <FormControl fullWidth className={classes.input}>
    <InputLabel id="demo-simple-select-label">Select Department</InputLabel>
    <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        required
        value={formValues.department_id}
        label="Select Department"
        onChange={handleChangeStatus2}
    >
        {selectedFacilityDepartments.map((department) => 
        {
          console.log(department)
          return(
            <MenuItem key={department.id} value={department.id}>
                {department.department}
            </MenuItem>
          )
        }
      )}
    </Select>
</FormControl>
         <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                value={formValues.status}
                label="Stauts"
                onChange={handleChangeStatus}
            >
                <MenuItem value="1">Active</MenuItem>
                <MenuItem value="0">Inactive</MenuItem>
            </Select>
            </FormControl>
             

     </DialogContent>
         <DialogActions>
             {
       loading ? <Button type='submit' variant='disabled'>    <RotatingLines
       strokeColor="#822824"
       strokeWidth="5"
       animationDuration="0.75"
       width="30"
       visible={loading}/> </Button> :
       <Button type='submit'
       variant='contained'
       > Add position  </Button>
     }
         </DialogActions>
         </form>
   </Dialog>
 </div>
  )
}

export default AddPosition
