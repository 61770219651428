import { AppBar, Toolbar  ,Typography, styled , Box, Divider, TextField, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom/dist'
import Body from './Body'
import NavBarLinks from './NavBarLinks'
import Page from '../../components/page/page'
import Nav from '../../components/AppBar/Header'
import StoresList from './components/StoresList'
import Footer from '../../views/Footer/Footer'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import axios from 'axios'
import { RotatingLines } from 'react-loader-spinner'
import { useDispatch } from 'react-redux'
import { getLocationsData } from '../../store/actions/locationActions'
const CenteredBox = styled(Box)(({theme})=> ({
  display:'flex',
  justifyContent:'center',
  alignItems:'center'
}))
const StyledBox =styled(Box)(({theme})=> ({
  background: theme.palette.secondary.main,
  marginTop: theme.spacing(-2),
  height:'80vh',
  display:'flex',
  alignItems:'center',
  justifyContent:'center'
}))
const StyledButton = styled(Button)(({theme})=> ({
  marginTop: theme.spacing(0.5),
  width:'120px',
  height:'54px',
  background:'#eae552',
  color:'#000',
  '&:hover': {
    color:'#fff'
  }
}))
const useStyles = makeStyles((theme) => ({
    root:{
        // padding: theme.spacing(3)
    }
}))
const initialValues = {
  zipcode:''
}
const Landing = () => {
  const navigate = useNavigate()
    const classes = useStyles()
    const [loading, setLoading] = React.useState(false)
    const [formValues, setFormValues] = React.useState(initialValues)
    const [locations, setLocations] = React.useState([]);
    const states = [];
    for (const record of locations) {
      const state = {
        lat: parseFloat(record.latitude),
        lng: parseFloat(record.longitude),
      };
      states.push(state);
    }
     console.log('locations',states)
    const handleChange = (e) => {
      const {name, value} = e.target
      setFormValues({...formValues, [name]:value})
    }
    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
      setLoading(true)
      e.preventDefault();
      try {
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
          params: {
            address: formValues.zipcode,
            key: 'AIzaSyDBOWIHjAHaXzSKa83rLM-gnQfrxG47R7I',
          },
        });
        // console.log(response, 'Response')
    
        if (response.data.results.length > 0) {
          const location = response.data.results[0].geometry.location;
    
          const dataToSend = {
            longitude: location.lng,
            latitude: location.lat,
          };
          
          // const backendResponse = await axios.post(`${process.env.REACT_APP_URL}closest-facilities`, dataToSend);
          dispatch(getLocationsData(dataToSend))
          setLoading(false)
          // setLocations(...backendResponse.data.data);
          navigate('/closest-facilities', )
        } else {
          setLocations([]);
        }
      } catch (err) {
      }
    };
    return (
    <Page
    title="Apply Now"
    >
      <Nav />
      <StyledBox>

    
      
        <Box className={classes.root}>
     <>
      <form onSubmit={handleSubmit}>
      {/* <Typography
      variant='h3'
      sx={{
        textAlign:'center',
        fontWeight:'bold',
        color:'#fff',
        mb:3
      

      }}
      >
        Join the family 
      </Typography> */}
      <Typography
      variant='h6'
      sx={{
        textAlign:'center',
        fontWeight:'bold',
        color:'#fff',
        fontSize: '30px', 

      }}
      >
        Join the family
      </Typography>
      {/* <Typography
      sx={{
        color:'#fff',
      }}
      >
        Find a location closest to home
      </Typography> */}
      <Typography
      variant='h6'
      sx={{
        textAlign:'center',
        fontWeight:'bold',
        color:'#fff',
        fontSize: '20px', 

      }}
      > Find a location closest to home
      </Typography>
      <TextField label="Enter your Zip Code here!"
      required
      name='zipcode'
      value={formValues.zipcode}
      onChange={handleChange}
      placeholder='Enter ZipCode to find Nearest locations'
      autoComplete='off'
      sx={{
        width:'100%',
        mt:1
      }}
      />
       {
       loading ? <Button type='submit' variant='disabled'>    <RotatingLines
       strokeColor="#822824"
       strokeWidth="5"
       animationDuration="0.75"
       width="30"
       visible={loading}/> </Button> :
       <StyledButton
       variant='contained'
       type='submit'
       sx={{
         
       }}
       >
         Search
       </StyledButton>
     }
      
        </form>
     </>

      {/* <Box>
      <StoresList location={locations} />

        </Box> */}
      </Box>
        </StyledBox>
          
        <Footer /> 
    </Page>
  )
}

export default Landing
