import { TableBody, TableHead, Pagination } from '@mui/material'
import { Table, Box, Button, styled, TableRow, TableCell,
IconButton,MenuItem, DialogActions, Select,
InputLabel,DialogTitle, Divider, TextField,
DialogContent, FormControl,Dialog, Input, InputAdornment
} from '@mui/material'
import MUIDataTable from "mui-datatables";
import { RotatingLines } from 'react-loader-spinner'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react'
import AddDepartment from './components/AddDepartment';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
const StyledTableRow = styled(TableRow)(({theme})=> ({
  background: theme.palette.primary.main
}))
const StyledTableCell = styled(TableCell)(({theme})=> ({
  color:'#fff'
}))
const useStyles=makeStyles((theme)=> ({
  input: {
      marginBottom: theme.spacing(3)
  }
}))
const initialValues = {
  department:'',
  facility_id:'',
  status:''
}

const Departments = () => {
  // const options = {
  //   filter: true,
  //   filterType: 'dropdown',
  //   responsive: 'scrollMaxHeight',
  //   selectableRows: 'none',
  //   fixedHeaderOptions: {
  //     xAxis: false,
  //     yAxis: true
  //   }
  // };
  // const columns = [
  //   {
  //     name: 'id',
  //     label: 'ID'
  //   },
  //   {
  //     name: 'department',
  //     label: 'Department'
  //   },
  //   {
  //     name: 'facility_id',
  //     label: 'Facility Id & Name',
  //     options: {
  //       filter: false,
  //       customBodyRender: (value, tableMeta, updateValue) => {
  //         const rowData = tableMeta.tableData[value].facility.name;
  //         return (
  //           <span>
  //             {`${value} - ${rowData}`}
  //           </span>
  //         );
  //       },
  //     },
  //   },
  //   {
  //     name: 'status',
  //     label: 'Status',
     
  //   },
  //   {
  //     name: 'actions',
  //     label: 'Actions',
  //     options: {
  //       filter: false,
  //       customBodyRender: (value, tableMeta, updateValue) => {
  //         const rowId = tableMeta.rowData[0];
  //         return (
  //           <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
  //             <IconButton>
  //               <EditIcon
  //                 sx={{ color: '#822824' }}
  //                 onClick={() => handleEdit(rowId)}
  //               />
  //             </IconButton>
  //             <IconButton>
  //               <DeleteIcon sx={{ color: '#822824' }} 
  //               onClick={() => handleDelete(rowId)}
  //               />
  //             </IconButton>
  //           </Box>
  //         );
  //       },
  //     },
  //   },
  // ];
  React.useEffect(()=> {
    getAllDepartments()
  }, [])
  const [open, setOpen] = React.useState(false)
const handleOpen = () => {
  setOpen(true)
}
const [editDialog, setEditDialog] = React.useState(false)


const handleDepartmentCreated = React.useCallback(() => {
  getAllDepartments();
}, []);
const [depData, setDepData] = React.useState([])
const [searchInput, setSearchInput] = React.useState("");
const [filteredDep, setFilteredDep] = React.useState([]);
const [loadingData, setLoadingData] = React.useState(null);
const [formValues, setFormValues] = React.useState(initialValues)
const [loading, setLoading] = React.useState(false)
const [selectedId, setSelectedId] = React.useState(null)
const [paginationData, setPaginationData] = React.useState({
  current_page: 1,
  last_page: 1,
  total: 0,
  per_page: 10,
});

const api = axios.create({
baseURL: process.env.REACT_APP_URL,
});
const getToken = () => {
return localStorage.getItem('token');
};

api.interceptors.request.use(
(config) => {
  const token = getToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
},
(error) => {
  return Promise.reject(error);
}
);

const handleSubmit = async (id) => {
setLoading(true)
try {
  const res = await api.put(`departments/${id}`, formValues)
  setLoading(false)
  alert(res.data.message)
  setFormValues(initialValues)
  setEditDialog(false)
  getAllDepartments()

}
catch(err) {
  console.log(err)
}
};
const handleChangeStatus = (e) => {
setFormValues({ ...formValues, status: e.target.value });
};
const getAllDepartments = async() => {
  setLoadingData(true)
  const res = await api.get(`departments`)
  // console.log(res.data.data[0], "The departments")
  setDepData(res.data.data[0])
  setPaginationData({
    current_page: res.data.data[0].current_page,
    last_page: res.data.data[0].last_page,
    total: res.data.data[0].total,
    per_page: res.data.data[0].per_page,
  });
  setLoadingData(false)
}
// const getAllDepartments = async () => {
//   let allDepartments = [];
//   let currentPage = 1;

//   while (true) {
//     try {
//       const res = await api.get(`departments?page=${currentPage}`);
//       const departments = res.data.data[0].data;
//       allDepartments = [...allDepartments, ...departments];

//       // Break the loop when there are no more pages
//       if (currentPage >= res.data.data[0].last_page) {
//         break;
//       }

//       currentPage++;
//     } catch (err) {
//       console.log(err);
//       break;
//     }
//   }

//   setDepData(allDepartments);
// };

const handleDelete =async (id) => {
  const confirmed = window.confirm("Are you sure you want to delete this department?");
  if(confirmed) {
    try{
      const res = await api.delete(`departments/${id}`)
  alert(res.data.message)
  getAllDepartments()
    }
    catch(err) {
      console.log(err)
    }
  }
  else {
    
  }
  
}
// console.log(depData)
const handleEdit = async (id) => {
  setSelectedId(id)
  setEditDialog(true)
  try {
    const res = await api.get(`departments/${id}`)
    // console.log(...res.data.data)
    setFormValues(...res.data.data)
  }
  catch(err) {
    console.log(err)
  }
}
// console.log(depData.data)
const handleChange = (e) => {
  const {name, value} = e.target
  setFormValues({...formValues, [name]: value})
  
}
const handleChangeSearch = (e) => {
  setSearchInput(e.target.value);
  filterDep(e.target.value);
}
const filterDep = (searchText) => {
  const filtered = depData.filter((dep) => {
    console.log(dep.facility.name, "THEEEEEE")
    return (
      dep.department.toLowerCase().includes(searchText.toLowerCase()) ||
      dep.facility.name.toLowerCase().includes(searchText.toLowerCase()) ||
      dep.facility_id.toString().includes(searchText)
    );
  });
  setFilteredDep(filtered);
};

  React.useEffect(() => {
    if (searchInput === "") {
    setFilteredDep(depData);
    } else {
    filterDep(searchInput);
    }
    }, [searchInput, depData]);
    // console.log(depData)
const classes = useStyles()
const [page, setPage] = React.useState(1);
  const rowsPerPage = 10;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const slicedData = filteredDep.slice(startIndex, endIndex);
  return (
    <div>
       <Box
       sx={{display:'flex', justifyContent:'space-between',
       mb:5
       }}
       >
        <Button 
        variant='contained'
        onClick={handleOpen}
        >
          Add Department
        </Button>
        <FormControl
        sx={{width:'40%'}}
        >
          <InputLabel> Search Department </InputLabel>
          <Input 
           value={searchInput}
           onChange={(e) => handleChangeSearch(e)}
          endAdornment={
            <InputAdornment position='end'>
            <SearchIcon />
            </InputAdornment>
          }
          
          
          />
        </FormControl>
      </Box>
     {
      depData && (
        <Table>
        <TableHead>
        <StyledTableRow>
            <StyledTableCell> Id</StyledTableCell>
            <StyledTableCell> Department</StyledTableCell>
            <StyledTableCell> Facility Id & Name</StyledTableCell>
            <StyledTableCell> Status</StyledTableCell>
            <StyledTableCell> Actions </StyledTableCell>
          </StyledTableRow>
        </TableHead>
          <TableBody>
            {
              slicedData.map((val, ind)=> {
                return(
                  <TableRow>
          <TableCell>{val.id}</TableCell>
          <TableCell>{val.department}</TableCell>
          <TableCell> {val.facility_id + " - " + val.facility.name}</TableCell>
          <TableCell> {val.status == 1 ? 'Active' : 'Inactive'} </TableCell>
          <TableCell>
            <Box
              sx={{display:'flex',}}
              >
                <IconButton>
                <EditIcon 
                sx={{color:'#822824'}}
                onClick={()=>handleEdit(val.id)}
                />
                </IconButton>
                <IconButton>      
                <DeleteIcon 
                sx={{color:'#822824'}}
                onClick={()=> handleDelete(val.id)}
                
                />
                </IconButton>
              
              </Box>
            </TableCell>
            </TableRow>
                )
              })
            }
            
          </TableBody>

      </Table>
    //   <MUIDataTable
    //   columns={columns}
    //   data={depData}
    //   options={options}
    //   title={'All Departments'}
    // />
      )
     }
     <Box sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            mt:3
            
            }}>
      <Pagination
       count={Math.ceil(filteredDep.length / rowsPerPage)} // Calculate the total number of pages
       page={page}
       onChange={handleChangePage}
       color="primary"
      /> 
      </Box>
      <AddDepartment open={open} 
      close={()=>setOpen(false)}
      onDepartmentCreated={handleDepartmentCreated}
      /> 
      <Dialog fullWidth open={editDialog} onClose={()=> setEditDialog(false)}>
        <DialogTitle>
            Update Department
        </DialogTitle>
        <Divider />
            <form onSubmit={(e)=>{
              e.preventDefault()
              handleSubmit(selectedId)
            }}>
        <DialogContent>

            <TextField fullWidth name='department' value={formValues.department}
            required className={classes.input}
            onChange={handleChange}
            label="Department Name"
            autoComplete='off'
            /> 
            <TextField fullWidth name='facility_id' value={formValues.facility_id}
            required className={classes.input}
            onChange={handleChange}
            label="Facility Id"
            autoComplete='off'
            /> 
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                value={formValues.status}
                label="Stauts"
                onChange={handleChangeStatus}
            >
                <MenuItem value="1">Active</MenuItem>
                <MenuItem value="0">Inactive</MenuItem>
            </Select>
            </FormControl>
                

        </DialogContent>
            <DialogActions>
                {
          loading ? <Button type='submit' variant='disabled'>    <RotatingLines
          strokeColor="#822824"
          strokeWidth="5"
          animationDuration="0.75"
          width="30"
          visible={loading}/> </Button> :
          <Button type='submit'
          variant='contained'
          > Update </Button>
        }
            </DialogActions>
            </form>
      </Dialog>
    </div>
  )
}

export default Departments
