import axios from "axios";

export const getLocationsData =  (data) => async (dispatch) => {
    const res =  await axios.post(`${process.env.REACT_APP_URL}closest-facilities`, data)
    // console.log(res.data.data)
    dispatch({
        type: 'GET_LOCATIONS_DATA',
        payload: res.data.data
      });

}