import React, {useState} from 'react'
import { Button, Select, MenuItem, Table,
TableHead, TableRow,TableCell, TableBody, Box, styled,
FormControl, InputLabel, Input, InputAdornment, Pagination
} from '@mui/material'
import { InfinitySpin } from 'react-loader-spinner';
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
const StyledTableRow = styled(TableRow)(({theme})=> ({
  background: theme.palette.primary.main
}))
const StyledTableCell = styled(TableCell)(({theme})=> ({
  color:'#fff'
}))

const Applications = () => {
  const [resumeLink, setResumeLink] = React.useState(null)
  const [fileLink, setFileLink] = React.useState(null)
  const [appData, setAppData] = React.useState([])
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);
  // const [perPage, setPerPage] = useState(10);
  const [paginationData, setPaginationData] = React.useState({
    current_page: 1,
    last_page: 1,
    total: 0,
    per_page: 10,
  });
  const [searchInput, setSearchInput] = useState('');
const [filteredAppData, setFilteredAppData] = useState([]);
  const api = axios.create({
    baseURL: process.env.REACT_APP_URL,
  });

  const getToken = () => {
    return localStorage.getItem('token');
  };
  
  api.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const getFileLink = async () => {
    try{
      const res = await api.get('export-applications')
      // console.log(res.data.data)
      setFileLink(res.data.data.file_url)
    }
    catch(err) {
      console.log(err)
    }
  }
  // const getAllApplications = async () => {
  //   try {
  //    const res = await api.get('all-applications')
  //   // console.log(res.data.data[0].data)
  //   setAppData(res.data.data[0].data)
  //   }
  //   catch(err) {
  //     console.log(err)
  //   }
  // }
  const getAllApplications = async () => {
    // console.log(page, "The page")
    try {
      const res = await api.get(`all-applications`);
      // console.log(res.data.data[0], 'In Application')
      // console.log(res.data.data[0].current_page, 'Pagination')
      setAppData(res.data.data[0]);
      // setCurrentPage(res.data.data[0].current_page);
      // setTotalPages(res.data.data[0].last_page);
      // setPerPage(res.data.data[0].per_page);
      setPaginationData({
        current_page: res.data.data[0].current_page,
        last_page: res.data.data[0].last_page,
        total: res.data.data[0].total,
        per_page: res.data.data[0].per_page,
      }); 
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(()=> {
    getFileLink()
    getAllApplications()
  }, [])
  // console.log(appData)
  const filterApplications = (searchText) => {
    const filtered = appData.filter((app) => {
      console.log(app, 'The app ++++')
      return (
        app.firstname.toLowerCase().includes(searchText.toLowerCase()) ||
        app.lastname.toLowerCase().includes(searchText.toLowerCase()) ||
        app.position.position.toLowerCase().includes(searchText.toLowerCase()) ||
        app.position.department.department.toLowerCase().includes(searchText.toLowerCase()) ||
        app.email.toLowerCase().includes(searchText.toLowerCase()) ||
        app.position.department.facility.name.toLowerCase().includes(searchText.toLowerCase()) ||
        app.experience.toLowerCase().includes(searchText.toLowerCase()) ||
        app.messageupdate.toLowerCase().includes(searchText.toLowerCase()) ||


        // app.experience.toLowerCase().includes(searchText.toLowerCase()) ||
        // (app.experience === 1 && searchText.toLowerCase() === 'yes') ||
        // (app.experience === 0 && searchText.toLowerCase() === 'no') ||
        app.phone.includes(searchText)
      );
    });
    setFilteredAppData(filtered);
  };
  React.useEffect(() => {
    if (searchInput === '') {
      setFilteredAppData(appData);
    } else {
      filterApplications(searchInput);
    }
  }, [searchInput, appData])
  const [page, setPage] = React.useState(1);
  const rowsPerPage = 10;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const slicedData = filteredAppData.slice(startIndex, endIndex);
  return (
    <div>
       <Box
       sx={{display:'flex', justifyContent:'space-between',
       mb:5
       }}
       >
        <Button 
        href={fileLink}
        variant='contained'
        endIcon={
          <FileDownloadIcon />
        }
        >
          Export All Applications
        </Button>
        <FormControl
        sx={{width:'40%'}}
        >
          <InputLabel> Search Application </InputLabel>
          <Input 
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          endAdornment={
            <InputAdornment position='end'>
            <SearchIcon />
            </InputAdornment>
          }
          
          
          />
        </FormControl>
      </Box>
      
      {
        appData && (
          <Table>
          <TableHead>
          <StyledTableRow>
              <StyledTableCell> Id</StyledTableCell>
              <StyledTableCell> Facility</StyledTableCell>
              <StyledTableCell> Position</StyledTableCell>
              <StyledTableCell> Department</StyledTableCell>
              <StyledTableCell> Full Name</StyledTableCell>
              <StyledTableCell> Email</StyledTableCell>
              <StyledTableCell> LTC Experience</StyledTableCell>
              <StyledTableCell> SMS</StyledTableCell>
              <StyledTableCell> Phone</StyledTableCell>
              <StyledTableCell> Resume</StyledTableCell>

            
            </StyledTableRow>
          </TableHead>
            <TableBody>
             {
              slicedData.map((val,ind)=> {
                // console.log(val.position.department.facility.name, "Hi++")
                return(
                  <TableRow>
                  <TableCell>{val.id}</TableCell>
                  <TableCell> {val.position.department.facility.name}</TableCell>
                  <TableCell> {val.position.position}</TableCell>
                  <TableCell> {val.position.department.department}</TableCell>
                  <TableCell> {val.firstname + ' ' + val.lastname} </TableCell>
                  <TableCell> {val.email}</TableCell>
                  <TableCell> {val.experience}</TableCell>
                  <TableCell> {val.messageupdate}</TableCell>
                  <TableCell> {val.phone}</TableCell>
                  <TableCell> 
                  <Button
                  variant='outlined'
                  href={val.resume}
                  target='_blank'
                  >
                    View Resume 
                  </Button>

                  </TableCell>
                    </TableRow>
                )
              })
             }
            </TableBody>
  
        </Table>

        )
      }
      
      <Box
  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    mt: 3,
  }}
>
  <Pagination
 count={Math.ceil(filteredAppData.length / rowsPerPage)} // Calculate the total number of pages
 page={page}
 onChange={handleChangePage}
 color="primary"
  />
</Box>


    </div>
  )
}

export default Applications
