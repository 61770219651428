import { Avatar, styled, Box, List, ListItem, 
  ListItemAvatar, ListItemButton, ListItemIcon, ListItemText,
   Typography, Button, Checkbox, FormControlLabel,
  FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { Link } from 'react-router-dom'

const CenteredBox = styled(Box)(({theme})=> ({
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  }))

const useStyles = makeStyles((theme)=> ({
    box: {
        background:'#822824',
        minHeighteight:'60vh',
        width:'100vh',
        boxShadow: '0px 4px 10px rgba(0,0,0, 0.5)',
        borderRadius:'10px',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)

    }

}))

const StoresList = (props) => {
  const {location} = props
  console.log(location, 'Hello')
    const classes = useStyles()
    const [val, setVal] = React.useState('');

  const handleChange = (event) => {
    setVal(event.target.value);
  };
  const [isChecked, setIsChecked] = React.useState(false);
  return (
    <div>
      <Typography variant='h4'
     sx={{fontWeight:'bold', textAlign:'center', color:'#fff',fontSize: '40px',}}
      >
      <span style={{ fontWeight: 'bold' }}>Select the location closest to you</span>
        </Typography>
        <CenteredBox>

        <Box className={classes.box}>
           {
            location.map((val, ind)=> {
              const distance = parseFloat(val.distance).toFixed(2);
                return(
                  <>
                  </>
              //       <List>
              //       <ListItem>
              //           <ListItemButton
              //           component={Link}
              //           to='/departments'
              //           sx={{
              //             '&:hover': {
              //                 backgroundColor: '#eae552', // Set the background color on hover
              //             },
              //         }}
              //           >
              //               <ListItemAvatar>
              //                       <Avatar 
              //                       sx={{height:'60px', width:'60px', mr:2}}
              //                       />
              //               </ListItemAvatar>
              //               <ListItemText
              //               sx={{color:'#000'}}
              // primary={val.name}
              // secondary={
              //     <React.Fragment>
              //     <Typography
              //       sx={{ display: 'inline' }}
              //       component="span"
              //       variant="body2"
              //   fontWeight="bold"
              //       // color="text.primary"
              //       >
              //       Address: 
              //     </Typography>
              //     {val.address}
              //     <Typography
              //   //   component="span"
              //   fontWeight="bold"
              //     variant="body2"
              //   //   color="text.primary"
              //     >
              //       Distance from your location: {distance} Km
              //     </Typography>
              //   </React.Fragment>
              // }
              // />
              // {/* <Typography
              // fontWeight="bold"
              // variant="body2"
              // sx={{color:'#000'}}
              // >
              //   Opened Positions : {val.pos}
              // </Typography> */}
              //           </ListItemButton>
              //       </ListItem>
              //   </List>
                )
            })
           }
           <FormControlLabel control={<Checkbox 
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
           sx={{
            color: '#fff',
            '&.Mui-checked': {
              color: "#fff",
            },
          }}
           />} label="Other" />
         {
  isChecked && (
    <Box>
      <Typography>Choose any facility below:</Typography>
      <Box
      sx={{
        p:3,
        mb:3
      }}
      > 
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Facilities</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={val}
          label="Facilities"
          onChange={handleChange}
          >
            
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
          </Box>
    </Box>
  )
}
            
        </Box>
          </CenteredBox>
    </div>
  )
}

export default StoresList
