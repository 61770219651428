import React from 'react'
import { TextField, Dialog, DialogActions, Button,
MenuItem, InputLabel, FormControl, DialogTitle, Divider,
Select, DialogContent
} from '@mui/material'
import { RotatingLines } from 'react-loader-spinner'
import { makeStyles } from '@mui/styles'
import axios from 'axios'
const useStyles=makeStyles((theme)=> ({
    input: {
        marginBottom: theme.spacing(3)
    }
}))

const initialValues = {
    department:'',
    facility_id:'',
    status:''
}

const AddDepartment = (props) => {
    const [formValues, setFormValues] = React.useState(initialValues)
    const [loading, setLoading] = React.useState(false)
    const handleChange = (e) => {
        const {name, value} = e.target
        setFormValues({...formValues, [name]: value})
}
const api = axios.create({
    baseURL: process.env.REACT_APP_URL,
  });
  const getToken = () => {
    return localStorage.getItem('token');
  };

  api.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    console.log(formValues)
    const res = await api.post('departments', formValues)
    setLoading(false)
    alert(res.data.message)
    setFormValues(initialValues)
    if (props.onDepartmentCreated) {
      props.onDepartmentCreated();
    }
    props.close()
  };
const handleChangeStatus = (e) => {
    setFormValues({ ...formValues, status: e.target.value });
  };
  const handleChangeStatus2 = (e) => {
    setFormValues({ ...formValues, facility_id: e.target.value });
  };
  const [facilityData, setFacilityData] = React.useState([])
  const getAllFacilities = async () => {
      try {
        const res = await api.get(`facilities`);
        // const facilities = res.data.data[0];
        setFacilityData(res.data.data[0]);
      } catch (err) {
        console.log(err);
      }
    
  
  };
  
  React.useEffect(() => {
    getAllFacilities();
  }, []);
  // console.log(facilityData)
    const classes = useStyles()
  return (
    <div>
       <Dialog fullWidth open={props.open} onClose={props.close}>
        <DialogTitle>
            Add Department
        </DialogTitle>
        <Divider />
            <form onSubmit={handleSubmit}>
        <DialogContent>

            <TextField fullWidth name='department' value={formValues.department}
            required className={classes.input}
            onChange={handleChange}
            label="Department Name"
            autoComplete='off'
            /> 
            {/* <TextField fullWidth name='facility_id' value={formValues.facility_id}
            required className={classes.input}
            onChange={handleChange}
            label="Facility Id"
            autoComplete='off'
            />  */}
            <FormControl fullWidth
            className={classes.input}
            >
            <InputLabel id="demo-simple-select-label">Facility</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                value={formValues.facility_id}
                label="Facility"
                onChange={handleChangeStatus2}
            >
              {
                facilityData.map((val, ind)=> {
                  return(
                    <MenuItem value={val.id}>{val.name}</MenuItem>

                  )
                })
              }
            </Select>
            </FormControl>
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                value={formValues.status}
                label="Stauts"
                onChange={handleChangeStatus}
            >
                <MenuItem value="1">Active</MenuItem>
                <MenuItem value="0">Inactive</MenuItem>
            </Select>
            </FormControl>
                

        </DialogContent>
            <DialogActions>
                {/* <Button variant='contained'
                sx={{mr:1}}
                type='submit'
                >
                    Create
                </Button> */}
                {
          loading ? <Button type='submit' variant='disabled'>    <RotatingLines
          strokeColor="#822824"
          strokeWidth="5"
          animationDuration="0.75"
          width="30"
          visible={loading}/> </Button> :
          <Button type='submit'
          variant='contained'
          > Add Department </Button>
        }
            </DialogActions>
            </form>
      </Dialog>
    </div>
  )
}

export default AddDepartment
