import React from 'react'
import { Table, TableCell, styled, TableRow, Button,
Dialog, DialogActions, DialogTitle, Box, TableHead,
IconButton, TableBody, Select, InputLabel, FormControl,
TextField,Divider, MenuItem, DialogContent, Pagination,
Input, InputAdornment,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles'
import { RotatingLines } from 'react-loader-spinner'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios'
import AddPosition from './components/AddPosition';
const Positions = () => {
  const StyledTableRow = styled(TableRow)(({theme})=> ({
    background: theme.palette.primary.main
  }))
  const StyledTableCell = styled(TableCell)(({theme})=> ({
    color:'#fff'
  }))
  const initialValues = {
    position:'',
    department_id:'',
    status:''
}
const [formValues, setFormValues] = React.useState(initialValues)
const [loading, setLoading] = React.useState(false)
const [searchInput, setSearchInput] = React.useState("");
const [filteredPos, setFilteredPos] = React.useState([]);
const [editDialog, setEditDialog] = React.useState(false)
const [posData , setPosData] = React.useState([])
const [selectedId, setSelectedId] =React.useState()
const [positionDialog, setPositionDialog] = React.useState(false)
const [paginationData, setPaginationData] = React.useState({
  current_page: 1,
  last_page: 1,
  total: 0,
  per_page: 10,
});
const api = axios.create({
  baseURL: process.env.REACT_APP_URL,
  });
  const getToken = () => {
  return localStorage.getItem('token');
  };
  
  api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
  );
  const getAllPositions = async () => {
    try {
      const res = await api.get(`positions`)
      // console.log(res.data)
      setPosData(res.data.data[0])
      // setPaginationData({
      //   current_page: res.data.data[0].current_page,
      //   last_page: res.data.data[0].last_page,
      //   total: res.data.data[0].total,
      //   per_page: res.data.data[0].per_page,
      // }); 
    }
    catch(err) {
        console.log(err)
    }
  }
  React.useEffect(()=> {
    getAllPositions()
  }, [])
  const handleEdit = async (id) => {
    setEditDialog(true)
    setSelectedId(id)
    try{
      const res = await api.get(`positions/${id}`)
      setFormValues(...res.data.data)
    }
    catch(err) {
      console.log(err)
    }
  }
  const handleDelete = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this position?");
    if(confirmed) {
      try{
        const res = await api.delete(`positions/${id}`)
    alert(res.data.message)
   getAllPositions()
      }
      catch(err) {
        console.log(err)
      }
    }
    else {
      
    }
  }
  const handleChangeStatus = (e) => {
    setFormValues({ ...formValues, status: e.target.value });
  }; 
const handleSubmit = async (id) => {
  setLoading(true)
  try {
    const res = await api.put(`positions/${id}`, formValues)
    alert(res.data.message)
    setEditDialog(false)
    setFormValues(initialValues)
    setLoading(false)
    getAllPositions()
  }
  catch(err) {
    console.log(err)
  }
}
  const handlePosCreated = React.useCallback(() => {
    getAllPositions()
  }, []);
  
  const handleChange = (e) => {
    const {name, value} = e.target
    setFormValues({...formValues, [name]: value})
   
}
const handleChangeSearch = (e) => {
  setSearchInput(e.target.value);
  filterPos(e.target.value);
}

const filterPos = (searchText) => {
  const filtered = posData.filter((val) => {
    // console.log()
    return (
      val.position.toLowerCase().includes(searchText.toLowerCase()) ||
      val.department.facility.name.toLowerCase().includes(searchText.toLowerCase()) ||
      val.department.department.toLowerCase().includes(searchText.toLowerCase()) ||
      val.department_id.toString().includes(searchText)
    );
  });
  setFilteredPos(filtered);
};

React.useEffect(() => {
  if (searchInput === "") {
    setFilteredPos(posData);
  } else {
    filterPos(searchInput);
  }
}, [searchInput, posData]);
const [page, setPage] = React.useState(1);
  const rowsPerPage = 10;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const slicedData = filteredPos.slice(startIndex, endIndex);
  return (
    <div>
    <Box
    sx={{display:'flex', justifyContent:'space-between',
    mb:5
    }}
    >
     <Button 
     variant='contained'
     onClick={()=> setPositionDialog(true)}
     >
       Add Position
     </Button>
     <FormControl
        sx={{width:'40%'}}
        >
          <InputLabel> Search Position </InputLabel>
          <Input 
           value={searchInput}
           onChange={(e) => handleChangeSearch(e)}
          endAdornment={
            <InputAdornment position='end'>
            <SearchIcon />
            </InputAdornment>
          }
          
          
          />
        </FormControl>
   </Box>
  {
   posData && (
     <Table>
     <TableHead>
     <StyledTableRow>
         <StyledTableCell> Id</StyledTableCell>
         <StyledTableCell> Facility</StyledTableCell>
         <StyledTableCell> Department Id & Name</StyledTableCell>
         <StyledTableCell> Position</StyledTableCell>
         <StyledTableCell> Status</StyledTableCell>
         <StyledTableCell> Actions </StyledTableCell>
       </StyledTableRow>
     </TableHead>
       <TableBody>
         {
           slicedData.map((val, ind)=> {
             return(
               <TableRow>
       <TableCell>{val.id}</TableCell>
       <TableCell>{val.department.facility.name}</TableCell>
       <TableCell>{val.department_id + ' - ' + val.department.department}</TableCell>
       <TableCell> {val.position}</TableCell>
       <TableCell> {val.status == 1 ? 'Active' : 'Inactive'} </TableCell>
       <TableCell>
         <Box
           sx={{display:'flex',}}
           >
             <IconButton>
             <EditIcon 
             sx={{color:'#822824'}}
             onClick={()=>handleEdit(val.id)}
             />
             </IconButton>
             <IconButton>      
             <DeleteIcon 
             sx={{color:'#822824'}}
             onClick={()=> handleDelete(val.id)}
             
             />
             </IconButton>
           
           </Box>
         </TableCell>
         </TableRow>
             )
           })
         }
         
       </TableBody>

   </Table>
   )
  }
    <Box sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            mt:3
            
            }}>
      <Pagination
      count={Math.ceil(filteredPos.length / rowsPerPage)} // Calculate the total number of pages
      page={page}
      onChange={handleChangePage}
      color="primary"
      /> 
      </Box>
   <AddPosition open={positionDialog} 
   close={()=> setPositionDialog(false)}
   posCreated={handlePosCreated}
   /> 
   <Dialog fullWidth open={editDialog} onClose={()=> setEditDialog(false)}>
     <DialogTitle>
         Update Position
     </DialogTitle>
     <Divider />
         <form onSubmit={(e)=> {
            e.preventDefault()
            handleSubmit(selectedId)
         }}>
     <DialogContent>

         <TextField fullWidth name='position' value={formValues.position}
         required 
        sx={{mb:3}}
         onChange={handleChange}
         label="Position"
         autoComplete='off'
         /> 
         <TextField fullWidth name='department_id' value={formValues.department_id}
         required 
        sx={{mb:3}}
         onChange={handleChange}
         label="Department Id"
         autoComplete='off'
         /> 
         <FormControl fullWidth>
         <InputLabel id="demo-simple-select-label">Status</InputLabel>
         <Select
             labelId="demo-simple-select-label"
             id="demo-simple-select"
             required
             value={formValues.status}
             label="Stauts"
             onChange={handleChangeStatus}
         >
             <MenuItem value="1">Active</MenuItem>
             <MenuItem value="0">Inactive</MenuItem>
         </Select>
         </FormControl>
             

     </DialogContent>
         <DialogActions>
             {
       loading ? <Button type='submit' variant='disabled'>    <RotatingLines
       strokeColor="#822824"
       strokeWidth="5"
       animationDuration="0.75"
       width="30"
       visible={loading}/> </Button> :
       <Button type='submit'
       variant='contained'
       > Update </Button>
     }
         </DialogActions>
         </form>
   </Dialog>
 </div>
  )
}

export default Positions
