const initialState = {
    data: null
}

const locationReducer = (state = initialState, action) => {
    switch(action.type) {

        case 'GET_LOCATIONS_DATA' : {
            return {
                ...state,
                ...action.payload,
                data :action.payload
            }
        }
        default: return state
    }
}

export default locationReducer