import { useRoutes } from "react-router-dom/dist";
import Landing from "./layouts/Landing/Landing";
import OpenedPos from "./layouts/Landing/components/OpenedPos";
import CurrentOpenings from "./layouts/Landing/components/CurrentOpenings";
import Dashboard from "./layouts/Admin/Dashboard/Dashboard";
import DashboardAdmin from "./views/Admin/DashboardAdmin/DashboardAdmin";
import Facilities from "./views/Admin/DashboardAdmin/Facilities/Facilities";
import Departments from "./views/Admin/DashboardAdmin/Departments";
import Positions from "./views/Admin/DashboardAdmin/Positions";
import Applications from "./views/Admin/DashboardAdmin/Applications";
import Login from "./views/Admin/Auth/Login";
import ProtectedRoutes from './components/ProtectedRoutes/ProtectedRoutes'
import { useSelector } from "react-redux";
import ClosestFacilities from "./layouts/Landing/components/ClosestFacilities";

export default function Router() {
    const isAuthenticated = useSelector((state)=> state.admin.isAuthenticated)
    console.log(isAuthenticated)
    let element = useRoutes([
        {
        path:'/',
        element : <Landing /> ,
       },
       {
        path:'/departments/:id',
        element: <OpenedPos />
       },
       {
        path:'/current-openings/:id',
        element: <CurrentOpenings />
       },
       {
        path:'/closest-facilities',
        element: <ClosestFacilities /> 
       },
       {element:<ProtectedRoutes isLogged={isAuthenticated}/> ,
       children:[
        {

            path:'admin', element: <Dashboard />,
            children: [
                
                {path:'dashboard', element:<DashboardAdmin />},
                {path:'facilities', element:<Facilities />},
                {path:'departments', element:<Departments />},
                {path:'positions', element:<Positions />},
                {path:'applications', element:<Applications />}
            ] 
           },
       ] 
    
    },
       
       
       {path:'admin-login', element:<Login />}
    ])
    return element
}