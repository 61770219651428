import { Button, Dialog, DialogActions, DialogContent,
   DialogTitle, Divider, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import { RotatingLines } from 'react-loader-spinner'

import axios from 'axios';
const useStyles=makeStyles((theme)=> ({
  input: {
      marginBottom: theme.spacing(3)
  }
}))
const DialogComp = (props) => {
    const initialValues ={
        name:'',
        address:'',
        state_id:0,
        city_id:0,
        country_id:1,
        zipcode:'',
        status:''
    }
    const [loading, setLoading] = React.useState(false)
    const [formValues, setFormValues] = React.useState(initialValues)
    const handleChange = (e) => {
            const {name, value} = e.target
            setFormValues({...formValues, [name]: value})
    }
    const api = axios.create({
        baseURL: process.env.REACT_APP_URL,
      });
      const getToken = () => {
        return localStorage.getItem('token');
      };

      api.interceptors.request.use(
        (config) => {
          const token = getToken();
          if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    const handleSubmit = async (e) => {
      e.preventDefault();
      if(formValues.zipcode.length===5) {
        try {
          const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${formValues.zipcode}&key=AIzaSyDBOWIHjAHaXzSKa83rLM-gnQfrxG47R7I`
          );
          console.log(response, 'Hello Jee ')
          const results = response.data.results;
          if (results.length > 0) {
            const location = results[0].geometry.location;
            const updatedFormValues = {
              ...formValues,
              longitude: location.lng,
              latitude: location.lat,
            };
            // setFormValues(updatedFormValues);
            // console.log(updatedFormValues)
            setLoading(true)
            const res = await api.post('facilities', updatedFormValues);
            setFormValues(initialValues)
            setLoading(false)
            alert(res.data.message)
            // console.log(res, 'Response')
            if (props.onCreateSuccess) {
              props.onCreateSuccess();
          }
          }
       
        }
        catch(err)
        {
          console.log(err)
        }
      }
        // try {
        //   // console.log(formValues)
         
        //   // console.log(res);
        // } catch (err) {
        //   console.log(err);
        // }
      };
    const handleChangeStatus = (e) => {
        setFormValues({ ...formValues, status: e.target.value });
      };
      const [stateData, SetStateData] = React.useState([])
      const getAllStates = async () => {
        const res = await api.get('states/1')
        // console.log(res.data.data)
        SetStateData(res.data.data)
      }

      React.useEffect(()=> {
          getAllStates()
      }, [])
      const [selectedState, setSelectedState] = React.useState(null);
  const [cityData, setCityData] = React.useState([]);
   const handleStatusChange = (e , value) => {
    const selectedStateObject = stateData.find((state) => state.name === value);
    const selectedStateId = selectedStateObject ? selectedStateObject.id : 0; // Get the ID of the selected state
    // console.log(selectedStateId); 
    setSelectedState(selectedStateObject);
    setFormValues({ ...formValues, state_id: selectedStateId, city_id: 0 });
    setCityData([]);
   }
   const handleCityChange = (e, value) => {
    const selectedCityObject = cityData.find((city) => city.name === value);
    const selectedCityId = selectedCityObject ? selectedCityObject.id : 0;
    setFormValues({ ...formValues, city_id: selectedCityId });
  };
   const getAllCitiesByState = async (stateId) => {
    // console.log(stateId)
    try {
      const res = await api.get(`cities/${stateId}`);
      // console.log(res)
      setCityData(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    if (selectedState) {
      getAllCitiesByState(selectedState.id);
    }
  }, [selectedState]);
    const classes= useStyles()
   
  return (
    <div>
      <Dialog fullWidth open={props.open} onClose={props.close}>
        <DialogTitle>
            Add Facility
        </DialogTitle>
        <Divider />
            <form onSubmit={handleSubmit}>
        <DialogContent>

            <TextField fullWidth name='name' value={formValues.name}
            required className={classes.input}
            onChange={handleChange}
            label="Name"
            autoComplete='off'
            /> 
            <TextField fullWidth name='address' value={formValues.address}
            required className={classes.input}
            onChange={handleChange}
            label="Address"
            autoComplete='off'
            /> 
                    <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={stateData.map((state) => state.name)}
              onChange={handleStatusChange}
              renderInput={(params) => <TextField {...params} label="Select State" 
              fullWidth
              required
              className={classes.input}
              />}
            />
            {
              selectedState ?
              <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={cityData.map((city) => city.name)} 
              onChange={handleCityChange}
              fullWidth
              renderInput={(params) => <TextField {...params} label="Select City" 
              required
              className={classes.input}
              />}
            />
            : null 
            }
           
            {/* <TextField fullWidth name='city_id' value={formValues.city_id}
            required className={classes.input}
            onChange={handleChange}
            label="City Id"
            autoComplete='off'
            /> 
            <TextField fullWidth name='state_id' value={formValues.state_id}
            required className={classes.input}
            onChange={handleChange}
            label="State Id"
            autoComplete='off'
            />  */}
            <TextField 
            fullWidth
            name="zipcode"
            value={formValues.zipcode}
            required
            className={classes.input}
            onChange={handleChange}
            label="Zip Code"
            autoComplete="off"
            /> 
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                value={formValues.status}
                label="Stauts"
                onChange={handleChangeStatus}
            >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
            </FormControl>
                

        </DialogContent>
            <DialogActions>
                {/* <Button variant='contained'
                sx={{mr:1}}
                type='submit'
                >
                    Create
                </Button> */}
                {
          loading ? <Button type='submit' variant='disabled'>    <RotatingLines
          strokeColor="#822824"
          strokeWidth="5"
          animationDuration="0.75"
          width="30"
          visible={loading}/> </Button> :
          <Button type='submit'
          variant='contained'
          > Create </Button>
        }
            </DialogActions>
            </form>
      </Dialog>
    </div>
  )
}

export default DialogComp
