import React from 'react'
import { AppBar, Box, Toolbar, Typography, 
    Grid, Divider,Button,
    styled, 
    Card,
    CardHeader} from '@mui/material'
import DialogComp from './DialogComp'
import { useParams } from 'react-router'
import axios from 'axios'

const StyledBox = styled(Box)(({theme})=> ({
    minHeight:'100vh',
    background: theme.palette.secondary.main,
    padding:theme.spacing(3)
}))
const StyledContainer=styled(Box)(({theme})=> ({
    height:'40vh',
    boxShadow: '0px 4px 10px rgba(38, 66, 82, 0.5)',
    borderRadius:'10px',
    padding: theme.spacing(3),
    background: theme.palette.primary.main
}))
const StyledButton = styled(Button)(({theme})=> ({
    marginTop: theme.spacing(0.5),
    background:'#eae552',
    color:'#000',
    '&:hover': {
      color:'#fff'
    }
  }))
  const StyledDepBox=styled(Card)(({theme})=> ({
    // height:'25vh',
    borderRadius:'10px',
    // background:theme.palette.secondary.main,
    background:'#e2e2e2',
    boxShadow: '0 4px 8px #822824',
    // padding: theme.spacing(2),
    // marginTop: theme.spacing(3)
  }))

const jobOpeningsData = [
    {
      position: "Frontend Developer",
      company: "ABC Software Solutions",
      location: "New York, USA",
      qualifications: "Bachelor's degree in Computer Science or related field. Proficiency in HTML, CSS, and JavaScript.",
      experience: "2 years",
    },
    {
      position: "Backend Developer",
      company: "XYZ Tech Services",
      location: "San Francisco, USA",
      qualifications: "Bachelor's degree in Computer Science or related field. Experience with Node.js and database management.",
      experience: "3 years",
    },
    {
      position: "Data Analyst",
      company: "Data Insights Inc.",
      location: "London, UK",
      qualifications: "Bachelor's degree in Statistics, Mathematics, or related field. Proficiency in data analysis tools.",
      experience: "2 years",
    },
    {
      position: "UX/UI Designer",
      company: "Design Innovations",
      location: "Berlin, Germany",
      qualifications: "Bachelor's degree in Design or related field. Experience in user interface design.",
      experience: "3 years",
    },
    {
      position: "Marketing Manager",
      company: "Global Marketing Agency",
      location: "Sydney, Australia",
      qualifications: "Bachelor's degree in Marketing or related field. Experience in digital marketing strategies.",
      experience: "5 years",
    },
    {
      position: "Sales Executive",
      company: "SalesPro Inc.",
      location: "Tokyo, Japan",
      qualifications: "Bachelor's degree in Business or related field. Strong communication and negotiation skills.",
      experience: "2 years",
    },
    {
      position: "Software Engineer",
      company: "Tech Innovators Ltd.",
      location: "Toronto, Canada",
      qualifications: "Bachelor's degree in Computer Science or related field. Proficiency in Java and software development.",
      experience: "4 years",
    },
    {
      position: "HR Specialist",
      company: "PeopleFirst HR",
      location: "Singapore",
      qualifications: "Bachelor's degree in Human Resources or related field. Experience in talent acquisition and management.",
      experience: "3 years",
    },
    {
      position: "Finance Analyst",
      company: "Financial Insights Corp.",
      location: "Zurich, Switzerland",
      qualifications: "Bachelor's degree in Finance or related field. Strong analytical and financial modeling skills.",
      experience: "4 years",
    },
    {
      position: "Operations Manager",
      company: "Efficiency Solutions Ltd.",
      location: "Dubai, UAE",
      qualifications: "Bachelor's degree in Business or related field. Experience in operations and process optimization.",
      experience: "5 years",
    },
  ];
const CurrentOpenings = () => {
  const {id} = useParams()
    
  const getPosData = async () => {
    const res = await axios.post(` ${process.env.REACT_APP_URL}specific-position`, {department_id:id})
    // console.log(res.data.position)
    setPosData(res.data.position)
  
  }
  const [posData, setPosData] = React.useState([])
  React.useEffect(()=> {
    getPosData()
  }, [])
  const [openDialog, setOpenDialog] =React.useState(false)
  const [posId, setPosId] = React.useState(null)
    const handleDialog = (id) => {
      setPosId(id)
        setOpenDialog(true)
    }
  return (
    <div>
       <AppBar position='static'>
        <Toolbar>
        <img  src="/assets/images/TreeAlone-1.png"
            width="80px"
            />
            <Typography>
              Current Openings
            </Typography>
        </Toolbar>
        </AppBar> 
        <StyledBox>
           <Grid container
       spacing={3}
       
       >
        {posData.map((val, ind)=> {
         
          return(
            <Grid item
            lg={4}
            md={6}
            sm={6}
            xs={12}
            >
                     {/* <StyledContainer>
                        <Box>
                            <Box sx={{display:'flex', justifyContent:'space-between'}}>
                            <Box>
                            <Typography
                            variant='h5'
                            fontWeight="bold"
                            sx={{color:'#d3d3d3'}}
                            >
                                {val.position}
                            </Typography>
                            <Typography
                            sx={{fontSize:'15px',
                            color:'#d3d3d3'
                          }}
                            >
                               {val.company}
                            </Typography>
                            <Typography
                            sx={{fontSize:'15px',
                            color:'#d3d3d3'
                          }}
                            >
                                {val.location}
                            </Typography>
                            </Box>
                            <Box>
                            <StyledButton
                        variant='contained'
                        onClick={handleDialog}
                        sx={{mt:2, float:'right', verticalAlign:'middle'}}
                        >
                            Apply Now
                        </StyledButton>
                            </Box>
                            </Box>
                           
                            
                            <Divider sx={{mt:1}} /> 
                            <Typography
                            sx={{
                                textDecoration:'underline',
                                fontWeight:'bold',
                                mt:1,
                                color:'#d3d3d3'
                              }}
                              >
                                Qualifications & Requirements: 
                            </Typography>
                            <Typography
                            sx={{color:'#d3d3d3'}}
                            >
                                {val.qualifications} 
                            </Typography>
                            <Typography
                            component="span"
                            sx={{
                                textDecoration:'underline',
                                fontWeight:'bold',
                                mt:1,
                                color:'#d3d3d3'
                              }}
                              >
                                Experience: 
                            </Typography>
                            <span
                            style={{color:'#d3d3d3'}}
                            > {val.experience} </span>
                            
                        </Box>
                       
                    </StyledContainer> */}
                    <StyledDepBox sx={{}}>
                      <CardHeader 
                      title={
                        <Typography
                            variant='h5'
                            fontWeight="bold"
                            sx={{color:'#fff'}}
                            >
                                {val.position}
                            </Typography> 

                      }
                      sx={{background:'#822824'}}
                      />
                     {/* <Typography
                            variant='h5'
                            fontWeight="bold"
                            sx={{}}
                            >
                                {val.position}
                            </Typography>
                              </CardHeader> */}
                        <Divider />
                        <Box
                        sx={{p:2}}
                        >

                        <Typography
                            sx={{fontSize:'15px',
                          }}
                            >
                               {val.company}
                            </Typography>
                        <Box
                        sx={{display:'flex', justifyContent:'center', mt:3}}
                        >
                             <Button
                        variant='contained'
                        onClick={() => handleDialog(val.id)}
                        sx={{mt:2, float:'right', verticalAlign:'middle'}}
                        >
                            Apply Now
                        </Button>
                        </Box>
                          </Box>
                </StyledDepBox>
                </Grid>
            )
        })}
       
       </Grid>
       <DialogComp open={openDialog} 
       close={()=> setOpenDialog(false)} 
       id={posId}
       />
       
        </StyledBox>
    </div>
  )
}

export default CurrentOpenings
