import { AppBar, Toolbar, Typography, styled,
Box,
Avatar, Stack
} from '@mui/material'
import React from 'react'
import AdminLoginForm from './AdminLoginForm'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

const StyledRoot = styled('div')(({theme})=> ({
    // backgroundImage: Gradients.Custom,
    height:'100vh',
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
    
  }))
  const CenteredBox = styled(Box)(({theme})=> ({
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  }))
  const StyledAppBar = styled(AppBar)(({theme})=> ({
    background:theme.palette.primary.main,
    position:'fixed',
  
  }))
  const StyledToolbar = styled(Toolbar)(({theme})=> ({
    display:'flex',
    justifyContent:'space-between'
   }))
const Login = () => {
  const navigate = useNavigate()
  const isAuthenticated = useSelector((state)=> state.admin.isAuthenticated)
  
  React.useEffect(() => {
    if (isAuthenticated) {
      navigate('/admin/dashboard');
    }
  }, [isAuthenticated]);
  return (
    <div>
       <StyledAppBar>
      <StyledToolbar>
       <Typography>
       Admin Login
        </Typography> 
      </StyledToolbar>
    </StyledAppBar>
    <StyledRoot>
        <Stack>
            
            <Typography variant='h4' sx={{textAlign:'center'}}> Admin Login </Typography>
            <Typography sx={{textAlign:'center', mb:'1rem'}}> Sign in on the internal platform </Typography>
                    <AdminLoginForm />
              
        </Stack>
    </StyledRoot>
    </div>
  )
}

export default Login
