import React from 'react'
import MUIDataTable from "mui-datatables";
import { Avatar, Box, Button, IconButton, Table, 
  TableBody, TableCell, TableHead, TableRow, styled,
TextField, FormControl, InputLabel, Select, MenuItem,
Dialog, DialogActions, DialogContent, DialogTitle, Pagination,
} from '@mui/material';
import { RotatingLines } from 'react-loader-spinner'
import DialogComp from './components/DialogComp';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// import EditDialog from './components/EditDialog';
import { makeStyles } from '@mui/styles';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Input } from '@mui/material';
const StyledTableRow = styled(TableRow)(({theme})=> ({
  // textTransform:'none'
  background: theme.palette.primary.main
}))
const StyledTableCell = styled(TableCell)(({theme})=> ({
  // textTransform:'none'
  color:'#fff'
}))
const StyledDeleteButton = styled(Button)(({theme})=> ({
  textTransform:'none',
  marginTop: theme.spacing(2),
  
}))
const useStyles = makeStyles((theme)=> ({
  input: {
      marginBottom: theme.spacing(3)
  },
}))
const Facilities = () => {
  const classes=useStyles()
  const initialValues ={
      name:'',
      address:'',
      city_id:0,
      state_id:0,
      country_id:1,
      zipcode:'',
      status:''
  }
  // const [editDialog, setEditDialog]=React.useState(false)
    const [formValues, setFormValues] = React.useState(initialValues)
    const [searchInput, setSearchInput] = React.useState("");
    const [filteredFacilities, setFilteredFacilities] = React.useState([]);
  

  // const options = {
  //   filter: true,
  //   filterType: 'dropdown',
  //   responsive: 'scrollMaxHeight',
  //   selectableRows: 'none',
  //   fixedHeaderOptions: {
  //     xAxis: false,
  //     yAxis: true
  //   }
  // };
  // const columns = [
  //   {
  //     name: 'id',
  //     label: 'ID'
  //   },
  //   {
  //     name: 'name',
  //     label: 'Name'
  //   },
  //   {
  //     name: 'address',
  //     label: 'Address'
  //   },
  //   {
  //     name: 'city_id',
  //     label: 'City ID',
    
  //   },
  //   {
  //     name: 'state_id',
  //     label: 'State ID',
    
  //   },
  //   {
  //     name: 'zipcode',
  //     label: 'Zip Code',
     
  //   },
  //   {
  //     name: 'status',
  //     label: 'Status',
     
  //   },
  //   {
  //     name: 'actions',
  //     label: 'Actions',
  //     options: {
  //       filter: false,
  //       customBodyRender: () => (
  //         <>
         
  //             <Box
  //             sx={{display:'flex', justifyContent:'space-between'}}
  //             >
  //               <IconButton>
  //               <EditIcon 
  //               sx={{color:'#822824'}}
  //               onClick={handleDialogOpen}
  //               />
  //               </IconButton>
  //               <IconButton>      
  //               <DeleteIcon 
  //               sx={{color:'#822824'}}
                
  //               />
  //               </IconButton>
              
  //             </Box>
  //         </>
  //       )
  //     }
  //   },
  // ];
  const [paginationData, setPaginationData] = React.useState({
    current_page: 1,
    last_page: 1,
    total: 0,
    per_page: 10,
  });
  const [open, setopen] =React.useState(false)
  const handleDialogOpen = () => {
      setopen(true)
  }
  const handleCreateSuccess = () => {
    setopen(false);
    getFacilitiesData()
  };
  const [facilitiesData, setFacilitiesData] = React.useState([])
  React.useEffect(()=> {
    getFacilitiesData()
  }, [])
  const api = axios.create({
    baseURL: process.env.REACT_APP_URL,
  });

  const getToken = () => {
    return localStorage.getItem('token');
  };
  
  api.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const getFacilitiesData = async () => {
    try {
      const res = await api.get(`facilities`);
      console.log(res.data.data[0], 'The response')
      setFacilitiesData(res.data.data[0]);
      // console.log(res.data.data[0].current_page, '++++++')
      // setPaginationData({
      //   current_page: res.data.data[0].current_page,
      //   last_page: res.data.data[0].last_page,
      //   total: res.data.data[0].total,
      //   per_page: res.data.data[0].per_page,
      // }); 
    } catch (err) {
      console.log(err);
    }
  };
 
  const handleDelete = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this facility?");
    if (confirmed) {
      try {
        const res = await api.delete(`facilities/${id}`);
        alert(res.data.message)
        getFacilitiesData(); // Fetch updated data and re-render the table
      } catch (error) {
        console.log(error);
      }
    } else {
    }
  };
  const [editFacilityId, setEditFacilityId] = React.useState(null);
  const [editDialog, setEditDialog] = React.useState(false)
  const handleEdit = async (id) => {
    setEditDialog(true)
    setSelectedFacilityId(id); 
    try {
      const res = await api.get(`facilities/${id}`);
      console.log(...res.data.data)
      setFormValues(...res.data.data)
  }
  catch(err) {
      console.log(err)
  }
  }
  const [selectedFacilityId, setSelectedFacilityId] = React.useState(null);
  const [loading, setLoading] = React.useState(false)
  const handleSubmit = async (id) => {
    setLoading(true)
    // console.log(id)
    try {
      const res = await api.put(`facilities/${id}`, formValues);
      setFormValues(initialValues)
      setLoading(false)
      getFacilitiesData();
      setEditDialog(false)
      alert(res.data.message)
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
const handleChangeStatus = (e) => {
    setFormValues({ ...formValues, status: e.target.value });
  };
  
  const handleChange = (e) => {
    const {name, value} = e.target
    setFormValues({...formValues, [name]: value})
    
}
const handleChangeSearch =(e) => {
  setSearchInput(e.target.value);
    filterFacilities(e.target.value);
}

const filterFacilities = (searchText) => {
const filtered = facilitiesData.filter((facility) => {
return (
  facility.name.toLowerCase().includes(searchText.toLowerCase()) ||
  facility.address.toLowerCase().includes(searchText.toLowerCase())
);
});
setFilteredFacilities(filtered);
};

React.useEffect(() => {
if (searchInput === "") {
setFilteredFacilities(facilitiesData);
} else {
filterFacilities(searchInput);
}
}, [searchInput, facilitiesData]);
const [page, setPage] = React.useState(1);
const rowsPerPage = 10;
const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const startIndex = (page - 1) * rowsPerPage;
const endIndex = startIndex + rowsPerPage;
const slicedData = filteredFacilities.slice(startIndex, endIndex);
  return (
    <div

    >
      <Box 
      sx={{display:'flex', justifyContent:'space-between',
    mb:5
    }}>
        <Button 
        variant='contained'
      
        onClick={handleDialogOpen}
        >
          Add Facility
        </Button>
        <FormControl
        sx={{width:'40%'}}
        >
          <InputLabel> Search Facility </InputLabel>
          <Input 
           value={searchInput}
           onChange={(e) => handleChangeSearch(e)}
          endAdornment={
            <InputAdornment position='end'>
            <SearchIcon />
            </InputAdornment>
          }
          
          
          />
        </FormControl>
      </Box>
      <Table
      className={classes.table}
      >
        <TableHead>
          <StyledTableRow>
            <StyledTableCell> ID</StyledTableCell>
            <StyledTableCell> Name</StyledTableCell>
            <StyledTableCell> Address</StyledTableCell>
            <StyledTableCell> City Id </StyledTableCell>
            <StyledTableCell> State Id</StyledTableCell>
            <StyledTableCell> Zip Code</StyledTableCell>
            <StyledTableCell> Actions </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {
            slicedData.map((val, ind)=> {
              return(
                <TableRow>
            <TableCell>{val.id}</TableCell>
            <TableCell>{val.name}</TableCell>
            <TableCell>{val.address}</TableCell>
            <TableCell>{val.city_id}</TableCell>
            <TableCell>{val.state_id}</TableCell>
            <TableCell>{val.zipcode}</TableCell>
            <TableCell>
            <Box
              sx={{display:'flex', justifyContent:'space-between'}}
              >
                <IconButton>
                <EditIcon 
                sx={{color:'#822824'}}
                onClick={()=>handleEdit(val.id)}
                />
                </IconButton>
                <IconButton>      
                <DeleteIcon 
                sx={{color:'#822824'}}
                onClick={()=> handleDelete(val.id)}
                
                />
                </IconButton>
              
              </Box>
            </TableCell>

          </TableRow>
              )
            })
          }
          
        </TableBody>
      </Table>
          <Box sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            mt:3
            
            }}>
      <Pagination
      count={Math.ceil(filteredFacilities.length / rowsPerPage)}
      page={page}
      color='primary'
      onChange={handleChangePage}
      /> 
      </Box>
        {/* <MUIDataTable
              columns={columns}
              data={facilitiesData}
              options={options}
              title={'All Facilities'}
            /> */}
            <DialogComp open={open} 
            onCreateSuccess={handleCreateSuccess}
            close={()=> setopen(false)} />
            {/* <EditDialog 
            open={editDialog}
            facilityId={editFacilityId}
            close={()=> setEditDialog(false)}
            /> */}
             <Dialog open={editDialog} onClose={()=> setEditDialog(false)} fullWidth>
        <DialogTitle>
            Edit Details 
        </DialogTitle>
        <DialogContent>
        <form onSubmit={(e)=>{
          e.preventDefault()
          handleSubmit(selectedFacilityId)}}>
        <DialogContent>

            <TextField fullWidth name='name' value={formValues.name}
            required className={classes.input}
            onChange={handleChange}
            label="Name"
            autoComplete='off'
            /> 
            <TextField fullWidth name='address' value={formValues.address}
            required className={classes.input}
            onChange={handleChange}
            label="Address"
            autoComplete='off'
            /> 
            <TextField fullWidth name='city_id' value={formValues.city_id}
            required className={classes.input}
            onChange={handleChange}
            label="City Id"
            autoComplete='off'
            /> 
            <TextField fullWidth name='state_id' value={formValues.state_id}
            required className={classes.input}
            onChange={handleChange}
            label="State Id"
            autoComplete='off'
            /> 
            <TextField fullWidth name='zipcode' value={formValues.zipcode}
            required className={classes.input}
            onChange={handleChange}
            label="Zip Code"
            autoComplete='off'
            /> 
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                value={formValues.status}
                label="Stauts"
                onChange={handleChangeStatus}
            >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
            </FormControl>
                

        </DialogContent>
            <DialogActions>
                {
          loading ? <Button type='submit' variant='disabled'>    <RotatingLines
          strokeColor="#822824"
          strokeWidth="5"
          animationDuration="0.75"
          width="30"
          visible={loading}/> </Button> :
          <Button type='submit'
          variant='contained'
          > Update </Button>
        }
            </DialogActions>
            </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Facilities
