import { Box, Card, Typography, styled,
List, ListItem, ListItemButton,ListItemText,
ListItemAvatar, Avatar, Select, 
InputLabel, MenuItem, FormControl,
FormControlLabel, Checkbox
} from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import axios from 'axios'

const StyledRoot= styled(Box)(({theme})=> ({
    minHeight:'100vh',
    background: theme.palette.secondary.main,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    padding:theme.spacing(4)
}))
const StyledBox = styled(Box)(({theme})=> ({
  background: theme.palette.primary.main,
}))
const useStyles = makeStyles((theme)=> ({
  typo:{
      fontWeight:'bold',
       textAlign:'center', 
       color:'#fff',
       marginBottom:theme.spacing(2),
       [theme.breakpoints.down('md')] : {
        fontSize:'1.5rem'
       }
  }
}))
const ClosestFacilities = () => {
    const data = useSelector((state)=> state.location.data)
    console.log(data)
  const classes = useStyles()
  const [age, setAge] = React.useState('');
  const [dataF, setData] = React.useState([])
  const [checked, setChecked] = React.useState(false);
  const navigate = useNavigate()
  const handleChange = (event) => {
    setAge(event.target.value);
    navigate(`/departments/${event.target.value}`)
    
  };
  const handleCheckChange = (event) => {
    setChecked(event.target.checked);

  }
  const getAllFacilities = async () => {
    const res = await axios.get(`${process.env.REACT_APP_URL}all-facilities`)
    console.log(res.data.data[0])
    setData(res.data.data[0])
  }
  React.useEffect(() => {
    getAllFacilities()
  }, [])
  return (
    <StyledRoot>
      <Box>

         <Typography
      variant='h4'
      className={classes.typo}
      
      >
        Select the location closest to you 
      </Typography>
      <StyledBox>
      {
        data ? 
        data[0].map((val, ind)=> {
          const distance = parseFloat(val.distance).toFixed(2);
          console.log(val.id)  
          return(
            <List
            sx={{background:'#822824'}}
            >
              <ListItem>
                  <ListItemButton
                  component={Link}
                  to={`/departments/${val.id}`}
                  sx={{
                    '&:hover': {
                        backgroundColor: '#e58c2d', // Set the background color on hover
                    },
                  }}
                  >
                      <ListItemAvatar>
                              <Avatar 
                              sx={{height:'60px', width:'60px', mr:2}}
                              />
                      </ListItemAvatar>
                      <ListItemText
                      sx={{color:'#000'}}
                      primary={val.name}
                      secondary={
                        <React.Fragment>
            <Typography
              sx={{ display: 'inline' }}
              component="span"
              variant="body2"
              fontWeight="bold"
              // color="text.primary"
              >
              Address: 
            </Typography>
            {val.address}
            <Typography
          //   component="span"
          fontWeight="bold"
          variant="body2"
          //   color="text.primary"
          >
              Distance from your location: {distance} miles
            </Typography>
          </React.Fragment>
        }
        />
        {/* <Typography
        fontWeight="bold"
        variant="body2"
        sx={{color:'#000'}}
        >
        Opened Positions : {val.pos}
      </Typography> */}
                  </ListItemButton>
              </ListItem>
          </List>
            )
          })
          : <Typography>
            No Data Found 
          </Typography>
      }
                 <Box
              sx={{display:'flex', justifyContent:'center'}}
              >
              <FormControlLabel control={<Checkbox 
               checked={checked}
               color='secondary'
               onChange={handleCheckChange}
              />} label="Other" />
              </Box>
              <Box
              sx={{p:2}}
              >
                {
                  checked ? 
                  <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Facilities</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Facilities"
                    onChange={handleChange}
                  >
                    {
                      dataF.map((val, ind)=> {
                        // console.log(val)
                        return(
                          <MenuItem value={val.id}>{val.name}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl> : null
                }
             
              </Box>
      
      </StyledBox>

        </Box>
    </StyledRoot>
    // <div>

    // <StyledRoot>
    //   <Box
    //   sx={{
    //     display:'flex',
    //     justifyContent:'center',
    //     alignItems:'center'
    //   }}
    //   >
    //     <Box>


      // <Typography
      // variant='h4'
      // className={classes.typo}
      
      // >
      //   Select the location closest to you 
      // </Typography>
      // {
      //   data ? 
      //   data[0].map((val, ind)=> {
      //     const distance = parseFloat(val.distance).toFixed(2);
      //     console.log(val.id)  
      //     return(
      //       <List
      //       sx={{background:'#822824'}}
      //       >
      //         <ListItem>
      //             <ListItemButton
      //             component={Link}
      //             to={`/departments/${val.id}`}
      //             sx={{
      //               '&:hover': {
      //                   backgroundColor: '#e58c2d', // Set the background color on hover
      //               },
      //             }}
      //             >
      //                 <ListItemAvatar>
      //                         <Avatar 
      //                         sx={{height:'60px', width:'60px', mr:2}}
      //                         />
      //                 </ListItemAvatar>
      //                 <ListItemText
      //                 sx={{color:'#000'}}
      //                 primary={val.name}
      //                 secondary={
      //                   <React.Fragment>
      //       <Typography
      //         sx={{ display: 'inline' }}
      //         component="span"
      //         variant="body2"
      //         fontWeight="bold"
      //         // color="text.primary"
      //         >
      //         Address: 
      //       </Typography>
      //       {val.address}
      //       <Typography
      //     //   component="span"
      //     fontWeight="bold"
      //     variant="body2"
      //     //   color="text.primary"
      //     >
      //         Distance from your location: {distance} miles
      //       </Typography>
      //     </React.Fragment>
      //   }
      //   />
      //   {/* <Typography
      //   fontWeight="bold"
      //   variant="body2"
      //   sx={{color:'#000'}}
      //   >
      //   Opened Positions : {val.pos}
      // </Typography> */}
      //             </ListItemButton>
      //         </ListItem>
      //     </List>
      //       )
      //     })
      //     : <Typography>
      //       No Data Found 
      //     </Typography>
      // }
    //   </Box>
    //           </Box>
              // <Box
              // sx={{display:'flex', justifyContent:'center'}}
              // >
              // <FormControlLabel control={<Checkbox 
              //  checked={checked}
              //  onChange={handleCheckChange}
              // />} label="Label" />
              // </Box>
              // <Box
              // >
              //   {
              //     checked ? 
              //     <FormControl fullWidth>
              //     <InputLabel id="demo-simple-select-label">Facilities</InputLabel>
              //     <Select
              //       labelId="demo-simple-select-label"
              //       id="demo-simple-select"
              //       value={age}
              //       label="Facilities"
              //       onChange={handleChange}
              //     >
              //       {
              //         dataF.map((val, ind)=> {
              //           console.log(val)
              //           return(
              //             <MenuItem value={val.id}>{val.name}</MenuItem>
              //           )
              //         })
              //       }
              //     </Select>
              //   </FormControl> : null
              //   }
             
              // </Box>
    // </StyledRoot>
    //   </div>
  )
}

export default ClosestFacilities
